import React from 'react';
import Helmet from 'react-helmet';
import { Header } from './Header';
import { Footer } from './Footer';
import '../sass/global/styles.scss';
import 'typeface-roboto-condensed';
import 'typeface-roboto';

const Layout = ({ children, location }) => (
  <div id="layout">
    <Helmet title="Home | Eastern Automation" />
    <div className="wide-wrapper">
      <Header location={location} />
      <main>{children}</main>
      <Footer location={location} />
    </div>
  </div>
)

export default Layout
